import Image from 'next/image';
import Link from 'next/link';
import { Badge } from '@/components/shadcn/badge';
import KebabPopover from '@/components/ui/header/KebabPopover';
import { redirectTo } from '@/utils/url';
import {
  getBlackFridaySaleLabel,
  shouldShowBlackFridaySale,
} from '@/utils/sale';
import { PRODUCTION_DOMAIN } from './Page';

const SaleBanner = () => {
  const label = getBlackFridaySaleLabel();
  return (
    <a
      className="z-50 hidden md:block"
      href="https://bee.beemaps.com/#Hivemapper-Bee-ProductSection">
      <Badge className="w-full rounded bg-azure-700 px-4 py-1 text-center text-base font-normal hover:bg-azure-800">
        {label}
      </Badge>
    </a>
  );
};

export default function Header() {
  const showSaleBanner = shouldShowBlackFridaySale();
  return (
    <header className="fixed top-0 z-50 flex h-14 w-full items-center bg-neutral-1000 px-4 md:h-12">
      <div className="flex w-full items-center justify-between">
        <div
          className="max-w-[128px] md:max-w-[128px]"
          onClick={() => redirectTo(PRODUCTION_DOMAIN)}>
          <Image
            src="https://hivemapper-static.s3.us-west-2.amazonaws.com/bee/beemaps-logo.svg"
            priority
            alt="logo"
            width={163}
            height={38}
            className="ml-0 h-12 w-full cursor-pointer md:ml-2"
          />
        </div>
        {showSaleBanner ? <SaleBanner /> : null}
        <div className="hidden md:flex">
          <div className="border-r pl-4 pr-6 text-neutral-600">
            <Link href="/privacy/overview">Privacy</Link>
          </div>
          <KebabPopover />
        </div>
      </div>
    </header>
  );
}
