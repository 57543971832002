import { FunctionComponent, SVGProps, useState } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import { capitalize, sanitizeUsername } from '@/utils/strings';
import BeeIcon from '@/components/ui/icons/BeeIcon';
import FleetsIcon from '@/components/ui/icons/FleetsIcon';
import AiTrainersIcon from '@/components/ui/icons/AiTrainersIcon';
import ScoutIcon from '@/components/ui/icons/ScoutIcon';
import NetworkIcon from '@/components/ui/icons/NetworkIcon';
import ConsoleIcon from '@/components/ui/icons/ConsoleIcon';
import ChevronDownIcon from '@/components/ui/icons/ChevronIcon';
import DiscordIcon from '@/components/ui/icons/DiscordIcon';
import XIcon from '@/components/ui/icons/XIcon';
import LoginIcon from '@/components/ui/icons/LoginIcon';
import CloseIcon from '@/components/ui/icons/CloseIcon';
import HamburgerIcon from '@/components/ui/icons/HamburgerIcon';
import SettingsIcon from '@/components/ui/icons/SettingsIcon';
import { ExternalUrls } from '@/utils/url';
import MapIcon from '@/components/ui/icons/MapIcon';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shadcn/select';
import { Badge } from '@/components/shadcn/badge';
import {
  getBlackFridaySaleLabel,
  shouldShowBlackFridaySale,
} from '@/utils/sale';

interface INavSection {
  label: string;
  subItems: INavItem[];
  isMobileOnly?: boolean;
}

interface INavItem {
  label: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  subItems?: INavItem[];
  url?: string;
  openInNewTab?: boolean;
}

const socialMediaLinks = [
  { label: 'X', icon: XIcon, url: 'https://x.com/Hivemapper' },
  {
    label: 'Discord',
    icon: DiscordIcon,
    url: 'https://discord.com/invite/FRWMKyy5v2',
  },
];

const SaleBanner = () => {
  const label = getBlackFridaySaleLabel();
  return (
    <a
      className="z-50"
      href="https://bee.beemaps.com/#Hivemapper-Bee-ProductSection">
      <Badge
        variant="outline"
        className="w-full justify-center rounded-md border-azure-700 bg-neutral-1000 p-3 text-center font-monserrat font-normal">
        {label}
      </Badge>
    </a>
  );
};

export default function MainNav() {
  const { pathname } = useRouter();
  const { user, updateCurrentOrganization } = useUser();
  const { username } = user || {};

  const navItems: INavSection[] = [
    {
      label: 'Explore',
      subItems: [
        {
          label: 'Map',
          url: '/map',
          icon: MapIcon,
        },
        {
          label: 'Network',
          icon: NetworkIcon,
          subItems: [
            {
              label: 'Coverage',
              url: '/network/coverage',
            },
            {
              label: 'AI Trainers Reviews',
              url: '/network/ai-trainers-reviews',
            },
            {
              label: 'Contributors',
              url: '/network/contributors',
            },
            {
              label: 'Regions',
              url: '/network/regions',
            },
          ],
        },
      ],
    },

    {
      label: 'Consume',
      subItems: [
        {
          label: 'Scout',
          url: '/scout',
          icon: ScoutIcon,
        },
        {
          label: 'Console',
          icon: ConsoleIcon,
          ...(username
            ? {
                subItems: [
                  {
                    label: 'Developers',
                    url: '/console/developers/api-key',
                  },
                  {
                    label: 'Organization',
                    url: '/console/organization/info',
                  },
                ],
              }
            : { url: '/console' }),
        },
      ],
    },
    {
      label: 'Contribute',
      subItems: [
        {
          label: 'AI Trainers',
          url: '/trainers',
          icon: AiTrainersIcon,
        },
        {
          label: 'Beekeeper',
          icon: FleetsIcon,
          url: '/fleets',
        },
        {
          label: 'Bee',
          icon: BeeIcon,
          url: ExternalUrls.BeeDashcamStore,
          openInNewTab: true,
        },
      ],
    },
    {
      label: 'Company',
      subItems: [
        {
          label: 'Use Cases',
          url: '/use-cases',
        },
        {
          label: 'Products',
          url: '/product',
        },
        {
          label: 'Contribute',
          url: '/contribute',
        },
        {
          label: 'Guilds',
          url: '/fleet-management',
        },
        {
          label: 'Blog',
          url: '/blog',
        },
        {
          label: 'Careers',
          url: '/careers',
        },
        {
          label: 'Privacy',
          url: '/privacy/overview',
        },
        {
          label: 'Terms of Service',
          url: '/tos/mapping-network#introduction',
        },
      ],
      isMobileOnly: true,
    },
  ];

  const isUrlActive = (url: string, path: string): boolean => {
    return url === path || (path.includes(url) && url !== '/');
  };

  const getInitialOpenSubItem = (path: string): string | null => {
    for (const section of navItems) {
      for (const item of section.subItems) {
        if (item.subItems) {
          for (const subItem of item.subItems) {
            if (isUrlActive(subItem.url ?? subItem.label, path)) {
              return item.label;
            }
          }
        }
      }
    }
    return null;
  };

  const [openSubItem, setOpenSubItem] = useState<string | null>(
    getInitialOpenSubItem(pathname),
  );
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleSubItem = (label: string) => {
    setOpenSubItem(prev => (prev === label ? null : label));
  };

  const currentOrganizationName = user?.organizations?.find(
    org => org._id === user.currentOrganization,
  )?.name;

  const showSaleBanner = shouldShowBlackFridaySale();

  return (
    <>
      <div
        data-open={isNavOpen}
        className="no-scrollbar fixed top-14 z-50 flex size-full shrink-0 flex-col overflow-scroll bg-neutral-900 pb-40 text-neutral-0 data-[open=false]:hidden md:top-12 md:h-full md:max-w-[232px] md:pb-36 md:data-[open=false]:block">
        {showSaleBanner ? (
          <div className="my-2 w-full px-4 md:hidden">
            <SaleBanner />
          </div>
        ) : null}
        <nav className="flex-1">
          {navItems.map(section => (
            <div
              key={section.label}
              data-mobile={section.isMobileOnly}
              className="group/navSection">
              <div className="block px-4 py-2 leading-none md:p-4 md:group-data-[mobile=true]/navSection:hidden">
                <span className="text-[10px] font-bold uppercase leading-none tracking-[2px] text-azure-400 md:text-xs md:tracking-[0.15rem]">
                  {section.label}
                </span>
              </div>
              <ul>
                {section.subItems.map(item => {
                  const Icon = item.icon;
                  const isSelected = openSubItem === item.label;
                  const isActive =
                    isUrlActive(item.url || item.label, pathname) ||
                    item.subItems?.some(subItem =>
                      isUrlActive(subItem.url ?? subItem.label, pathname),
                    );
                  return (
                    <div
                      key={item.label}
                      data-active={isActive}
                      className="group/navItem"
                      data-selected={isSelected}>
                      {item.subItems ? (
                        <>
                          <button
                            onClick={() => toggleSubItem(item.label)}
                            className="flex w-full items-center px-4 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navItem:text-neutral-0 group-data-[selected=true]/navItem:text-neutral-0 md:px-6 md:group-data-[mobile=true]/navSection:hidden">
                            {Icon && (
                              <Icon className="mr-4 hidden size-6 fill-current transition-colors md:block" />
                            )}
                            <span className="transition-colors">
                              {item.label}
                            </span>
                            <ChevronDownIcon className="ml-auto fill-current transition-all group-data-[selected=true]/navItem:rotate-180" />
                          </button>
                          {openSubItem === item.label && (
                            <ul>
                              {item.subItems.map(subItem => {
                                const isSubItemActive = isUrlActive(
                                  subItem.url || subItem.label,
                                  pathname,
                                );

                                return (
                                  <li
                                    key={subItem.label}
                                    className="group/navSubItem"
                                    data-active={isSubItemActive}>
                                    <Link
                                      href={subItem.url || '#'}
                                      target={
                                        subItem.openInNewTab
                                          ? '_blank'
                                          : '_self'
                                      }
                                      className="flex w-full items-center px-6 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navSubItem:bg-azure-700 group-data-[active=true]/navSubItem:text-neutral-0">
                                      <span className="md:ml-8">
                                        {subItem.label}
                                      </span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </>
                      ) : (
                        <li>
                          <Link
                            href={item.url || '#'}
                            target={item.openInNewTab ? '_blank' : '_self'}
                            className="flex items-center px-4 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navItem:bg-azure-700 group-data-[active=true]/navItem:text-neutral-0 md:px-6 md:group-data-[mobile=true]/navSection:hidden">
                            {Icon && (
                              <Icon className="mr-4 hidden size-6 fill-current transition-colors md:block" />
                            )}
                            <span className="transition-colors">
                              {item.label}
                            </span>
                          </Link>
                        </li>
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
          ))}
          <div className="block md:hidden">
            <ul className="flex gap-6 p-4">
              {socialMediaLinks.map(link => {
                const Icon = link.icon;
                return (
                  <li key={link.label}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" flex items-center text-neutral-600 hover:text-neutral-0">
                      <Icon className="size-6 fill-current transition-colors" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
        <div className="fixed bottom-0 flex h-[92px] w-full items-center bg-neutral-1000 font-bold text-neutral-0 md:max-w-[232px]">
          {username ? (
            <div className="flex size-full items-center justify-start gap-3 fill-current p-4 ">
              <div className="flex w-full flex-col gap-2">
                <div className="flex items-center justify-between">
                  <span className="w-[160px] truncate font-normal leading-none">
                    {sanitizeUsername(username)}
                  </span>
                  <Link href="/account">
                    <SettingsIcon className="size-6 fill-neutral-600" />
                  </Link>
                </div>
                {user?.organizations &&
                  user?.organizations.length > 1 &&
                  currentOrganizationName && (
                    <div className="w-full">
                      <Select
                        onValueChange={value => {
                          updateCurrentOrganization(value);
                          Router.reload();
                        }}>
                        <SelectTrigger className="h-7 bg-neutral-900 text-xs">
                          <SelectValue placeholder={currentOrganizationName} />
                        </SelectTrigger>
                        <SelectContent className="bg-neutral-900 text-xs">
                          {user?.organizations.map(org => (
                            <SelectItem key={org._id} value={org._id}>
                              {capitalize(org.name)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <Link href="/login">
              <button className="flex size-full items-center justify-start gap-3 p-4">
                <LoginIcon className="size-6 fill-current" />
                <span>Login / Sign Up</span>
              </button>
            </Link>
          )}
        </div>
      </div>
      <button
        className="fixed right-4 top-4 z-50 block text-neutral-0 md:hidden"
        onClick={() => setIsNavOpen(prev => !prev)}>
        {isNavOpen ? (
          <CloseIcon className="size-6 fill-current p-1" />
        ) : (
          <HamburgerIcon className="size-6 fill-current" />
        )}
      </button>
    </>
  );
}
