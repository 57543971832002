import { isNowPastTargetISODate } from './dates';

const getBlackFridaySaleLabel = () => {
  const isDec2nd = isNowPastTargetISODate('2024-12-01T23:59:59-08:00');
  const label = isDec2nd
    ? '$40 OFF Bee LTE - Offer Ends Today'
    : '$40 OFF Bee LTE - Offer Ends Soon';
  return label;
};

const shouldShowBlackFridaySale = () =>
  !isNowPastTargetISODate('2024-12-02T23:59:59-08:00');

export { getBlackFridaySaleLabel, shouldShowBlackFridaySale };
